

export const InitHeaderSticky = () => {

  const header = document.querySelector<HTMLElement>('header.header')
  const offset = header?.offsetHeight

  header && offset && window.addEventListener("scroll", (e: Event) => {
    if (window.scrollY > 0) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  })
}
