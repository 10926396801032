export const InitRegionWidget = () => {
  const headerEl = document.querySelector<HTMLElement>('.header')!!
  const regionWidgetEl = headerEl.querySelector<HTMLElement>('.region-widget')!!
  const regionWidgetTriggerEl = headerEl.querySelector<HTMLElement>('.region-widget-trigger')!!
  const regionWidgetCloseEl = regionWidgetEl.querySelector<HTMLElement>('.region-widget-flyout-close')!!
  const regionWidgetMarketEl = regionWidgetEl.querySelector<HTMLElement>('.region-widget-flyout-markets')!!

  regionWidgetTriggerEl.addEventListener('click', (event: Event) => {
    regionWidgetEl.classList.toggle('is-open')
  })

  regionWidgetCloseEl.addEventListener('click', (event: Event) => {
    regionWidgetEl.classList.remove('is-open')
  })

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key == "Escape") {
      regionWidgetEl.classList.remove('is-open')
    }
  })
}
