interface ObserverOptions {
  root: Element|null
  rootMargin: string
  threshold: number
}

const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
  entries.map((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-visible')
      observer.unobserve(entry.target)
    }
  })
}

export const InitContentFadeIn = () => {

  const observerOptions: ObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.05
  }
  const targets = document.querySelectorAll<HTMLElement>('.content-fade-in')
  const observer = new IntersectionObserver(handleIntersection, observerOptions)

  targets.forEach((target) => {
    if (target.offsetHeight < 750) {
      observer.observe(target)
    } else {
      target.classList.add('is-visible')
    }
  })
}
